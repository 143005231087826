import type { LocationQueryRaw } from '#vue-router';
import { type FragmentType, graphql } from '@/gql';
import { useDeviceType } from 'theme/composables/useDeviceType';

type SubNav = {
  title: string;
  href: LocationQueryRaw | string;
  condition: boolean;

  variant: 'link' | 'primary-button';
  isExternalLink: boolean;
};

export const useHeaderSubNavsForGuest = (): Ref<SubNav[]> => {
  // 未ログイン時はviwerのcurrentChannelは取得できないので、undefinedを渡している
  const { isPartnerLockIn } = usePartnerLockIn(() => undefined);

  const base: SubNav[] = [
    {
      title: 'よくあるご質問',
      href: { path: '/faq' },
      variant: 'link',
      condition: !isPartnerLockIn.value,
      isExternalLink: false,
    },
    {
      title: 'ログイン',
      href: { path: '/login' },
      variant: 'link',
      condition: true,
      isExternalLink: false,
    },
  ];
  const diagnosisSubNav: SubNav = {
    title: '診断をはじめる',
    href: { path: '/chat/diagnosis' },
    variant: 'primary-button',
    condition: !isPartnerLockIn.value,
    isExternalLink: false,
  };

  const { isMobile } = useDeviceType();
  return computed(() => {
    if (isMobile.value) {
      return [diagnosisSubNav, ...base];
    }
    return [...base, diagnosisSubNav];
  });
};

const UseHeaderNavsViewerForMemberFragment = graphql(`
  fragment UseHeaderNavsViewerForMember on Viewer {
    latestDiagnosis {
      uuid
    }
    currentChannel
  }
`);

export const useHeaderSubNavForMember = (
  viewer: Ref<
    FragmentType<typeof UseHeaderNavsViewerForMemberFragment> | null | undefined
  >
): ComputedRef<SubNav[]> => {
  const fragmentViewer = useComputedFragment(
    UseHeaderNavsViewerForMemberFragment,
    viewer
  );

  const { isPartnerLockIn } = usePartnerLockIn(
    () => fragmentViewer.value?.currentChannel
  );
  const { isNewDiagnosisResultTarget } = useNewDiagnosisResultTarget(
    () => fragmentViewer.value?.currentChannel
  );

  return computed((): SubNav[] => {
    const viewer = fragmentViewer.value;

    const diagnosisSubNav: SubNav = isNewDiagnosisResultTarget.value
      ? {
          title: '再診断する',
          href: {
            path: '/chat/diagnosis',
          },
          variant: 'link',
          condition: true,
          isExternalLink: false,
        }
      : viewer?.latestDiagnosis != null
        ? {
            title: '診断結果を見る',
            href: {
              path: `/diagnosis/result/${viewer.latestDiagnosis.uuid}`,
            },
            variant: 'link',
            condition: true,
            isExternalLink: false,
          }
        : {
            title: '診断を受ける',
            href: { path: '/chat/diagnosis' },
            variant: 'link',
            condition: viewer != null && !isPartnerLockIn.value,
            isExternalLink: false,
          };

    const messageNav: SubNav = {
      title: 'メッセージ一覧',
      href: { path: '/my/messages' },
      variant: 'link',
      condition: true,
      isExternalLink: false,
    };

    const seminarNav: SubNav = {
      title: 'マネーセミナー',
      href: { path: '/seminar' },
      variant: 'link',
      condition: viewer != null && !isPartnerLockIn.value,
      isExternalLink: false,
    };

    const okanekoMagazineNav: SubNav = {
      title: 'オカネコマガジン',
      href: 'https://okane-kenko.jp/media/?utm_source=header-nav',
      variant: 'link',
      condition: viewer != null && !isPartnerLockIn.value,
      isExternalLink: true,
    };

    const settingNav: SubNav = {
      title: '設定',
      href: { path: '/settings' },
      variant: 'link',
      condition: true,
      isExternalLink: false,
    };

    // 冗長だが過渡期で順番も違うためひとつずつNavを定義している
    if (isNewDiagnosisResultTarget.value) {
      return [
        {
          title: 'ホーム (マイページ)',
          href: { path: '/home' },
          variant: 'link',
          condition: true,
          isExternalLink: false,
        },
        messageNav,
        diagnosisSubNav,
        settingNav,
        seminarNav,
        okanekoMagazineNav,
        {
          title: 'よくあるご質問',
          href: { path: '/faq' },
          variant: 'link',
          condition: true,
          isExternalLink: false,
        },
      ];
    }

    return [
      {
        title: 'マイページ',
        href: { path: '/my' },
        variant: 'link',
        condition: true,
        isExternalLink: false,
      },
      messageNav,
      seminarNav,
      okanekoMagazineNav,
      diagnosisSubNav,
      settingNav,
    ];
  });
};
