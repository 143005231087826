<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router';
import IconExternalLink from '~/assets/icons/outline/external-link.svg?component';
import { type FragmentType, graphql } from '~/gql';
import ButtonClose from '~theme/components/atoms/ButtonClose.vue';
import UserThumbnail from '~theme/components/atoms/UserThumbnail.vue';

const AppHeaderDrawerNewViewerFragment = graphql(/* GraphQL */ `
  fragment AppHeaderDrawerNewViewer on Viewer {
    nickname
    currentChannel
    latestDiagnosis {
      result {
        personality {
          type
        }
      }
    }
  }
`);

const props = defineProps<{
  viewer: FragmentType<typeof AppHeaderDrawerNewViewerFragment>;
}>();

const viewer = useComputedFragment(
  AppHeaderDrawerNewViewerFragment,
  () => props.viewer
);

const emit = defineEmits<{
  closeDrawer: [];
  logout: [];
}>();

type Link = {
  label: string;
  props:
    | {
        to: RouteLocationRaw;
      }
    | {
        href: RouteLocationRaw;
      };
  isExternalLink: boolean;
};

const link = (
  label: string,
  props:
    | {
        to: RouteLocationRaw;
      }
    | {
        href: RouteLocationRaw;
      },
  isExternalLink: boolean
): Link => ({
  label,
  props,
  isExternalLink,
});

const { isPartnerLockIn } = usePartnerLockIn(
  () => viewer.value?.currentChannel
);

// 現状OaaSに展開しないので 楽天インハウスアドバイザー と ZJK でマネーセミナー
// とか隠す処理とかはオミットしてる
const menu = computed<Link[]>(() => [
  link('ホーム (マイページ)', { to: { path: '/home' } }, false),
  link('メッセージ一覧', { to: { path: '/my/messages' } }, false),
  link(
    viewer.value.latestDiagnosis ? '再診断する' : '診断する',
    {
      to: { path: '/chat/diagnosis' },
    },
    false
  ),
  link('設定', { to: { path: '/settings' } }, false),
]);

const subMenu = computed<Link[]>(() =>
  [
    !isPartnerLockIn.value
      ? link('マネーセミナー', { to: { path: '/seminar' } }, false)
      : null,
    !isPartnerLockIn.value
      ? link(
          'オカネコマガジン',
          {
            href: 'https://okane-kenko.jp/media/?utm_source=header-nav',
          },
          true
        )
      : null,
    link('よくあるご質問', { to: { path: '/faq' } }, false),
  ].filter((l: Link | null): l is Link => !!l)
);
</script>

<template>
  <nav :class="$style.drawer">
    <div :class="$style.top">
      <div :class="$style.closeSection">
        <ButtonClose
          :class="[$style.drawer__close, $style.drawer__item, $style.close]"
          @click="emit('closeDrawer')"
        />
      </div>
      <div :class="[$style.drawer__item, $style.profile]">
        <UserThumbnail
          :class="$style.profile__avatar"
          :personality-type="viewer.latestDiagnosis?.result.personality.type"
        />
        <p :class="$style.profile__nickname">{{ viewer.nickname }}</p>
      </div>
    </div>
    <ul :class="[$style.menu, $style.drawer__item]">
      <li v-for="m in menu" :key="m.label" :class="$style.menu__item">
        <NuxtLink :class="$style.menu__link" v-bind="m.props">{{
          m.label
        }}</NuxtLink>
      </li>
    </ul>
    <div :class="[$style.drawer__item, $style.hr]" />
    <ul :class="[$style.subMenu, $style.drawer__item]">
      <li v-for="m in subMenu" :key="m.label" :class="$style.subMenu__item">
        <section v-if="m.isExternalLink" :class="$style.externalLink">
          <NuxtLink
            v-if="m.isExternalLink"
            :class="$style.subMenu__link"
            v-bind="m.props"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ m.label }}
          </NuxtLink>
          <IconExternalLink />
        </section>
        <NuxtLink v-else :class="$style.subMenu__link" v-bind="m.props">
          {{ m.label }}
        </NuxtLink>
      </li>
      <li :class="$style.subMenu__item" @click="emit('logout')">ログアウト</li>
    </ul>
  </nav>
</template>

<style lang="scss" module>
.drawer {
  background-color: $color-surface-primary;
  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;

  &__close {
    align-self: flex-end;
  }
}

.closeSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 0;
}

.close {
  width: 16px;
  height: 16px;
  color: $color-text-and-object-medium-emphasis;
}

.profile {
  display: flex;
  align-items: center;
  gap: 16px;

  &__avatar {
    width: 80px;
  }

  &__nickname {
    font-size: 16px;
    font-weight: bold;
    flex: 1;
    /* 親が flex なので overflow-wrap を機能させるために min-width の指定が必要 */
    min-width: 0;
    overflow-wrap: break-word;
  }

  &:nth-child(n + 2) {
    margin-top: 8px;
  }
}

.menu {
  font-size: 16px;
  font-weight: bold;
  &__link {
    color: $color-text-and-object-high-emphasis;
  }
  &__item:nth-child(n + 2) {
    margin-top: 16px;
  }
}

.subMenu {
  font-size: 14px;
  &__link {
    color: $color-text-and-object-high-emphasis;
  }
  &__item:nth-child(n + 2) {
    margin-top: 16px;
  }
}

.externalLink {
  display: flex;
  align-items: center;
  gap: 4px;
}

.hr {
  height: 1px;
  width: 100%;
  background-color: $color-border-low-emphasis;
}
</style>
