<script setup lang="ts">
import { wrapElementTransparentlyForTransition } from './utils/wrapElementTransparentlyForTransition';
import { Transition, mergeProps } from 'vue';

const props = withDefaults(
  defineProps<{
    duration?: number;
    timingFunction?: string;
  }>(),
  {
    duration: 200,
    timingFunction: 'ease-out',
  }
);
const slots = defineSlots<{
  default: () => VNode[];
}>();
const attrs = useAttrs();

const render = () => {
  const generateTransitionComponent = (children: () => VNode[]) =>
    h(
      Transition,
      {
        mode: 'out-in',
        duration: props.duration,
        onBeforeEnter(el: Element) {
          if (el instanceof HTMLElement) {
            el.style.width = '0';
          }
        },
        onEnter(el: Element) {
          if (el instanceof HTMLElement) {
            el.style.width = `${el.scrollWidth}px`;
          }
        },
        onBeforeLeave(el: Element) {
          if (el instanceof HTMLElement) {
            el.style.width = `${el.scrollWidth}px`;
          }
        },
        onLeave(el: Element) {
          // To avoid failure of first collapse.
          if (el instanceof HTMLElement && el.scrollWidth !== 0) {
            el.style.width = '0';
          }
        },
      },
      children
    );

  const wrapperProps = mergeProps(attrs, {
    style: {
      overflow: 'hidden',
      transition: `width ${props.duration}ms ${props.timingFunction}`,
    },
  });

  return generateTransitionComponent(
    wrapElementTransparentlyForTransition(slots, 'div', wrapperProps)
  );
};
</script>

<template>
  <render />
</template>
